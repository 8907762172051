import toast from 'react-hot-toast';
import { DocumentCategory, LoanData } from './interfaces';

interface DocumentInformation {
  missing: boolean;
  header?: string;
  doc?: DocumentCategory;
  description?: string;
}

export const inputProps = {
  autoComplete: 'off',
  readOnly: true,
};

const options = {
  enableHighAccuracy: true,
  timeout: 8000,
  maximumAge: 0,
};

export function getLocation(): Promise<GeolocationCoordinates> {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      const error = new Error('Geolocation is not supported by this browser.');
      handleGeolocationError(error);
      reject(error);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position.coords),
      (error: GeolocationPositionError) => {
        handleGeolocationError(error);
        reject(error);
      },
      options
    );
  });
}

function handleGeolocationError(error: GeolocationPositionError | Error): void {
  console.error('Geolocation error:', error);

  // Show a toast message to the user
  toast.error(
    'Unable to retrieve your location. Please check your settings and try again.'
  );
}

export const getMissingRequiredDocument = (
  loanProduct: LoanData,
  documents: DocumentCategory[]
) => {
  const documentInformation: DocumentInformation = { missing: true };
  documents.some((document: DocumentCategory) => {
    const userDocStatus = document.userData?.status;

    if (
      loanProduct.documents?.includes(document.id) ||
      document.required === 0
    ) {
      const statuses = ['archived', 'rejected'];
      const status = userDocStatus ? userDocStatus.toLowerCase() : '';
      const statusMessage = {
        archived: 'is archived',
        rejected: 'has been rejected',
      };
      documentInformation.header = `Upload your ${document.name}`;
      documentInformation.doc = document;

      if (statuses.includes(status)) {
        documentInformation.description = `Your ${document.name} ${
          statusMessage[status as 'archived' | 'rejected']
        }. Kindly submit a valid document for your loan to be processed`;
        return true;
      } else if (!document.userData) {
        documentInformation.description = `Kindly submit a valid ${document.name} for your loan to be processed`;
        return true;
      }
    }
    return false;
  });
  if (!documentInformation.description) {
    documentInformation.missing = false;
  }
  return documentInformation;
};

export const copyToClipBoard = async (text: string) => {
  const successMessage = 'Copied Successfully!';
  try {
    await navigator.clipboard.writeText(text);

    toast.success(successMessage as string);
  } catch (err: any) {
    const message = err.message || 'Failed to copy';
    toast.error(message as string, { id: 'referral_link_copy' });
  }
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormLayout } from '../../layout/FormLayout';
import * as Yup from 'yup';
import { Controltypes, ScreenState } from '../../bnpl';
import { SelectField } from '../../../base/SelectField';
import { Textfield } from '../../../base/Textfield';
import { ProductInformation } from '../../order-information/ProductInformation';
import { Spacer } from '../../../base/Spacer';
import useApplicationStore from '../../../../state';
import { BankDetails } from '../../../../utils/interfaces';

const initialValues = {};

const validationSchema = Yup.object({
  account_number: Yup.string()
    .min(6, 'Enter a valid account number')
    .max(20, 'Enter a valid account number')
    .required('Account number is required'),
  bank_code: Yup.string().required('Please choose a bank'),
});

function CreateMandate({ onBack, onNext, type }: Controltypes) {
  const [bankDetails, setBankDetails] = useState<BankDetails | null>(null);
  const [banks, setBanks] = useState<Array<{ label: string; value: string }>>();
  const [stage, setStage] = useState('no-account');

  const [
    getBanks,
    verifyBank,
    createMandate,
    bnpl_values,
    fetchMandates,
    addAccount,
    setScreen,
    loading,
  ] = useApplicationStore((state) => [
    state.getBanks,
    state.verifyBank,
    state.createMandate,
    state.bnpl_values,
    state.fetchMandates,
    state.addAccount,
    state.setBNPLScreen,
    state.loading,
  ]);

  const onSubmit = useCallback(
    async (values: any) => {
      if (stage === 'no-account') {
        const bankDetails = await verifyBank({
          account_number: values.account_number,
          ...values,
        });

        setBankDetails({ ...bankDetails });
        setStage('verify-account');
      } else if (stage === 'verify-account') {
        try {
          if (type !== 'bank') {
            createMandateFunction();
          } else {
            addAccountFunction();
          }
        } catch (error) {
          console.error(error);
        }
      } else if (stage === 'mandate-created') {
        onNext();
      }
    },
    [bankDetails, stage]
  );

  const addAccountFunction = async () => {
    await addAccount({
      account_number: bankDetails?.account_number ?? '',
      bank_code: bankDetails?.bank_code ?? '',
    });

    setScreen(ScreenState.CREATE_CARD);
  };

  const createMandateFunction = async () => {
    await createMandate({
      account_number: bankDetails?.account_number ?? '',
      bank_code: bankDetails!.bank_code,
      amount: bnpl_values.requested_amount,
      start_date: new Date().toString(),
      address: bnpl_values?.address ?? 'temporary address',
    });

    setStage('mandate-created');
    fetchMandates();
  };

  useEffect(() => {
    const getBankFunction = async () => {
      try {
        const banks = await getBanks();
        setBanks([...banks]);
      } catch (error) {
        console.error(error);
      }
    };

    getBankFunction();
  }, []);

  const data = useMemo(() => {
    if (bankDetails) {
      return [
        {
          title: "Sender's Name",
          value: bankDetails?.account_name,
        },
        {
          title: "Sender's Account Number",
          value: bankDetails?.account_number,
        },
      ];
    }
  }, [bankDetails]);

  const mandateData = [
    {
      title: 'Emandate Account Name',
      value: ' NIBSS Direct Debit Emandate Activation Account',
    },
    {
      title: 'Emandate Activation Account Number',
      value: '9880218357',
    },
    {
      title: 'Emandate Bank Name',
      value: ' Paystack-Titan ',
    },
  ];

  return (
    <FormLayout
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onBack={onBack}
      title={
        stage !== 'mandate-created'
          ? 'You need to add an account for your repayments when due'
          : `Kindly transfer ₦50 to any of the NIBSS accounts listed below to activate your mandate. Once the transfer is complete, please ensure 
                your account has at least ₦100 available, as the system requires this amount to confirm that your account can be debited.`
      }
      loading={loading}
    >
      <Spacer height={20} />

      {stage !== 'mandate-created' && (
        <>
          <SelectField
            name="bank_code"
            label="Bank Name"
            keyField="value"
            options={banks ?? []}
          />

          <Spacer height={32} />

          <Textfield
            name="account_number"
            label="Account Number"
            type="number"
          />
        </>
      )}

      {stage !== 'no-account' && (
        <>
          {stage !== 'mandate-created' && <Spacer height={20} />}

          {stage === 'mandate-created' && (
            <ProductInformation
              title={stage !== 'mandate-created' ? 'Confirm your account' : ''}
              content={mandateData}
            />
          )}

          <Spacer height={12} />
          <ProductInformation
            title={stage !== 'mandate-created' ? 'Confirm your account' : ''}
            content={data}
          />

          <Spacer height={12} />

          {stage !== 'mandate-created' && (
            <p
              className="sm-bold textfield-info pointer text-center"
              onClick={() => setStage('no-account')}
            >
              Wrong account?
            </p>
          )}

          {stage === 'mandate-created' && (
            <p className="sm-regular">
              Confirm once you have and your mandate would be instantly
              activated.
            </p>
          )}
        </>
      )}
    </FormLayout>
  );
}

export { CreateMandate };

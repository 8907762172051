// import { ScreenState } from "../components/bnpl/bnpl";

export const ScreenState = {
  OFFER_SELECTION: 'OFFER_SELECTION',
  ORDER_INFORMATION: 'ORDER_INFORMATION',
  CONFIGURABLE_FORM: 'VEHICLE_VALIDATION',
  ACCOUNT_INFORMATION: 'ACCOUNT_INFORMATION',
  PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
  EDUCATIONAL_INFORMATION: 'EDUCATIONAL_INFORMATION',
  BVN_VALIDATION: 'BVN_VALIDATION',
  SELFIE_CHECK: 'SELFIE_CHECK',
  REVIEW_TERMS: 'REVIEW_TERMS',
  PAYMENT_METHODS: 'PAYMENT_METHODS',
  CREATE_MANDATE: 'CREATE_MANDATE',
  CREATE_CARD: 'CREATE_CARD',
  PAYMENT: 'PAYMENT',
  CONFIRMATION: 'CONFIRMATION',
  ADD_BANK: 'ADD_BANK',
  PIN: 'PIN',
  DOCUMENTS: 'DOCUMENTS',
  NONE: 'None',
  GUARANTOR: 'GUARANTOR',
};

export const sideSteps = [
  {
    screen: ScreenState.OFFER_SELECTION,
    title: 'Select Product',
  },
  {
    screen: ScreenState.GUARANTOR,
    title: 'Add Guarantor',
  },
  {
    screen: ScreenState.ORDER_INFORMATION,
    title: 'Order Information',
  },
  {
    screen: ScreenState.CONFIGURABLE_FORM,
    title: 'Dynamic Form',
  },
  {
    screen: ScreenState.ACCOUNT_INFORMATION,
    title: 'Account Information',
  },
  {
    screen: ScreenState.PERSONAL_INFORMATION,
    title: 'Personal Information',
  },
  {
    screen: ScreenState.EDUCATIONAL_INFORMATION,
    title: 'Educational Information',
  },
  {
    screen: ScreenState.BVN_VALIDATION,
    title: 'BVN Validation',
  },
  {
    screen: ScreenState.SELFIE_CHECK,
    title: 'Selfie Check',
  },
  {
    screen: ScreenState.REVIEW_TERMS,
    title: 'Review Terms',
  },
  {
    screen: ScreenState.CREATE_MANDATE,
    title: 'Create Mandate',
  },
  {
    screen: ScreenState.CREATE_CARD,
    title: 'Create Card',
  },
  {
    screen: ScreenState.PAYMENT,
    title: 'Payment',
  },
  {
    screen: ScreenState.CONFIRMATION,
    title: 'Confirmation',
  },
];

export type ProductInformationProp = {
  title?: string;
  content?: Array<ContentInfo>;
  summary?: { title: string; value: string | number };
  htmlTitle?: string;
};

export type ContentInfo = {
  title: string;
  value: string | number;
  info?: string;
};

export type WithChildrenProp = {
  children: React.ReactNode;
};

export type TableHeader = {
  title: string;
  type: string;
  accessor?: string;
};

export type ContentScreenProp = {
  setScreen: React.Dispatch<React.SetStateAction<string>>;
};

export type TextFieldProp = {
  type: string;
  label: string;
  name: string;
  disabled?: boolean;
  hint?: string;
};

export type Controltypes = {
  onBack: () => void;
  onNext: () => void;
  addClick?: () => void;
  type?: string;
};

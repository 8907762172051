import { useMemo } from 'react';
import {
  getValidTenorPeriodsForProduct,
  money,
  repaymentDates,
} from '../../../utils/formatter';
import { ScreenState } from '../bnpl';
import useApplicationStore from '../../../state';
import { LoanData } from '../../../utils/interfaces';
import { LoanOffering } from './LoanOffering';
import { AmountSelection } from './AmountSelection';
import { FormLayout } from '../layout/FormLayout';
import { useTranslation } from '@lendsqr/lingua-react';
import { addDays, startOfToday } from 'date-fns';
import * as Yup from 'yup';
import { Spacer } from '../../base/Spacer';
import { PhoneField } from '../../base/PhoneField';
import { Tenor } from '../../base/Tenor';

const OfferSelection = () => {
  const { translate } = useTranslation();

  const [
    loan_data,
    setBNPLValues,
    country_code,
    logged_in,
    bnpl_values,
    user_profile_details,
    checkUser,
    loading,
    loanType,
    next,
  ] = useApplicationStore((state) => [
    state.loan_data as LoanData,
    state.setBNPLValues,
    state.country_code,
    state.logged_in,
    state.bnpl_values,
    state.user_profile_details,
    state.checkUser,
    state.loading,
    state.loanType,
    state.next,
  ]);

  const onSubmit = (values: any) => {
    setBNPLValues(values);

    if (
      logged_in === 'success' &&
      bnpl_values?.phone_number.replace('+', '') ===
        user_profile_details?.phone_number
    ) {
      next(ScreenState.OFFER_SELECTION, ScreenState.ORDER_INFORMATION);
    } else {
      checkUser(bnpl_values.phone_number);
    }
  };

  const loanOfferings = useMemo(() => {
    try {
      const offering = JSON.parse(
        loan_data?.product?.attributes['loan-offerings'] ?? null
      );

      if (offering == 0) {
        return null;
      }

      return offering;
    } catch (error) {
      console.error(error);
    }

    return undefined;
  }, [loan_data]);

  const validTenorPeriods = getValidTenorPeriodsForProduct(loan_data);

  const minDate = startOfToday();
  const maxDate = addDays(startOfToday(), 14);

  const dateMessage = translate('lender-web-app-select-date-range', {
    min: repaymentDates(minDate),
    max: repaymentDates(maxDate),
  });

  const amountMessage = translate('lender-web-app-amount-range', {
    min_amount: money(loan_data?.product.min_amount, loan_data?.currency),
    max_amount: money(loan_data?.product.max_amount, loan_data?.currency),
  });
  const numberTypeError = translate('lender-web-app-provide-numeric-value');

  const validationSchema = useMemo(() => {
    const schema = Yup.object({
      requested_amount: Yup.number()
        .typeError(numberTypeError)
        .min(loan_data?.product?.min_amount as number, amountMessage)
        .max(loan_data?.product?.max_amount, amountMessage)
        .required(translate('lender-web-app-enter-an-amount')),

      phone_number: Yup.string()
        .min(6, translate('lender-web-app-phone-min'))
        .max(20, translate('lender-web-app-phone-max'))
        .required(translate('lender-web-app-phone-number-error')),
    }) as any;

    if (loanType === 'pay_later') {
      schema.proposed_tenor = Yup.number()
        .typeError(numberTypeError)
        .min(
          loan_data?.product?.min_tenor_value,
          translate('lender-web-app-min-tenor', {
            min_tenor_value: `${loan_data?.product?.min_tenor_value}`,
            max_tenor_period: `${loan_data?.product?.min_tenor_period}`,
          })
        )
        .max(
          loan_data?.max_tenor_value,
          translate('lender-web-app-max-tenor', {
            max_tenor_value: `${loan_data?.product?.max_tenor_value}`,
            max_tenor_period: `${loan_data?.product?.max_tenor_period}`,
          })
        )
        .required(translate('lender-web-app-provide-numeric-value'));

      schema.proposed_tenor_period = Yup.string()
        .oneOf(
          validTenorPeriods,
          translate('lender-web-app-select-valid-tenor')
        )
        .required(translate('lender-web-app-select-tenor-period'));

      schema.proposed_payday = Yup.date()
        .min(minDate, dateMessage)
        .required(`Please enter a date after ${repaymentDates(minDate)}`);
    }

    return schema;
  }, [translate, loan_data, loanType]);

  const initialValues = useMemo(() => {
    return {
      ...bnpl_values,
    };
  }, [loan_data]);

  return (
    <FormLayout
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      loading={loading}
      title={!loanOfferings ? 'How much do you need' : ''}
    >
      {loanOfferings && (
        <div>
          <LoanOffering product={loan_data} data={loanOfferings} />

          <Spacer height={40} />

          <PhoneField
            country_code={country_code}
            value=""
            name="phone_number"
            label="Phone Number"
          />

          {loanType === 'pay_later' && (
            <>
              <Spacer height={32} />
              <Tenor />
            </>
          )}
        </div>
      )}

      {!loanOfferings && (
        <AmountSelection minDate={minDate} maxDate={maxDate} />
      )}
    </FormLayout>
  );
};

export { OfferSelection };

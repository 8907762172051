import { useEffect, useState } from 'react';
import { CreateMandate } from './CreateMandate';
import { SelectMandate } from './SelectMandate';
import useApplicationStore from '../../../../state';
import { getValidMandates } from '../../../../utils/formatter';

interface BnplCardsType {
  onBack: () => void;
  onNext: () => void;
}

function Mandate({ onBack, onNext }: BnplCardsType) {
  const [mandateStatus, setMandateStatus] = useState('idle');
  const [stage, setStage] = useState('idle');

  const [fetchMandates, bnpl_values, mandates] = useApplicationStore(
    (state) => [state.fetchMandates, state.bnpl_values, state.mandates]
  );

  const setStageFromMandates = (mandates: any) => {
    if (mandates?.length > 0) {
      setStage('select');
    } else {
      setStage('create');
    }
  };

  useEffect(() => {
    const getMandates = async () => {
      try {
        let allMandates = await fetchMandates();

        allMandates = getValidMandates(
          allMandates,
          bnpl_values.proposed_payday,
          bnpl_values.requested_amount
        );

        setMandateStatus('success');

        setStageFromMandates(allMandates);
      } catch (error) {
        console.error(error);
      }
    };

    getMandates();
  }, []);

  const backClick = () => {
    if (
      stage === 'create' &&
      getValidMandates(
        mandates,
        bnpl_values.proposed_payday,
        bnpl_values.requested_amount
      )?.length > 0
    ) {
      setStage('select');
    } else {
      onBack();
    }
  };

  const nextClick = () => {
    onNext();
  };

  const handleAddClick = () => {
    setStage('create');
  };

  return (
    <>
      {mandateStatus === 'success' && stage === 'create' && (
        <CreateMandate onBack={onBack} onNext={onNext} />
      )}

      {mandateStatus === 'success' && stage === 'select' && (
        <SelectMandate
          onBack={backClick}
          onNext={nextClick}
          addClick={handleAddClick}
        />
      )}
    </>
  );
}

export { Mandate };

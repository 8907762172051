import shallow from 'zustand/shallow';
import useApplicationStore from '../../../state';
import { FileObject } from '../../File-Upload';
import { Footer } from '../Footer';
import { BNPLBody } from '../layout/BNPLBody';
import { Spacer } from '../../base/Spacer';
import DocumentIcon from '../../../assets/document-solid.svg';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from '@lendsqr/lingua-react';
import { FileProps } from '../../base/Camera';

function BNPLFileUpload() {
  const { translate } = useTranslation();
  const [file, setFile] = useState<FileProps | null>(null);
  const inputFile = useRef(null);

  const acceptedFileTypes: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'application/pdf': ['.pdf'],
  };

  const [missingDoc, loading, uploadUserDoc, bnpl_conf, back] =
    useApplicationStore(
      (state) => [
        state.missingDoc,
        state.loading,
        state.uploadUserDoc,

        state.bnpl_conf,
        state.back,
      ],
      shallow
    );

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        toast.error(translate('invitation-web-app-file-type-error'));
        return;
      }

      setFile({
        file: acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    noClick: true,
  });

  const onBack = () => {
    back();
  };

  const submit = async () => {
    const fileBlob = file?.file as FileObject;

    if (fileBlob?.size && fileBlob.size / 1000000 > 5) {
      toast.error(translate('invitation-web-app-file-size-error'));
      return;
    }

    try {
      await uploadUserDoc(
        fileBlob as Blob,
        missingDoc?.doc.id as number,
        missingDoc?.userDocId as number
      );

      setFile(null);
      return;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <BNPLBody
        title={
          bnpl_conf?.file_upload?.description ??
          'Upload your necessary documents'
        }
      >
        <div className="file-uploader flex-center">
          <label className="doc-holder flex-center" {...getRootProps()}>
            {!file && <div className="doc-background "></div>}

            <input
              type="file"
              id="file-upload"
              {...getInputProps()}
              ref={inputFile}
            />

            {!file && <img width={100} src={DocumentIcon} alt="File upload" />}

            {file &&
              typeof file.file !== 'string' &&
              file.file.type === 'application/pdf' && (
                <p className="body-regular">{file.file?.name}s</p>
              )}

            {file &&
              typeof file.file !== 'string' &&
              file?.file?.type.includes('image') && (
                <img
                  src={file.preview}
                  alt="missing file"
                  className="bnpl-upload-image"
                />
              )}
          </label>

          <Spacer height={16} />

          <div className="doc-info flex">
            {file && (
              <>
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C3.8033 0 0 3.8033 0 8.5C0 13.1967 3.8033 17 8.5 17C13.1967 17 17 13.1967 17 8.5C17 3.8033 13.1967 0 8.5 0ZM13.5285 6.94294L8.01501 12.4565C7.60661 12.8649 6.94294 12.8649 6.53453 12.4565L6.15165 12.0736L3.54805 9.46997C3.13964 9.06156 3.13964 8.3979 3.54805 7.98949L3.93093 7.60661C4.33934 7.1982 5.003 7.1982 5.41141 7.60661L7.27477 9.46997L11.6652 5.07958C12.0736 4.67117 12.7372 4.67117 13.1456 5.07958L13.5285 5.46246C13.9369 5.87087 13.9369 6.53453 13.5285 6.94294Z"
                    fill="#31C5A1"
                  />
                </svg>
                <Spacer width={8} />
                <p className="sm-regular">
                  {translate('invitation-web-app-file-upload-success')}
                </p>
              </>
            )}

            {!file && (
              <>
                <img src="/info.svg" />
                <Spacer width={8} />
                <p className="sm-regular">{`${
                  missingDoc?.instructionHeader ||
                  translate(
                    'invitation-web-app-default-file-upload-instruction'
                  )
                }`}</p>
              </>
            )}
          </div>
        </div>

        {/* <FileUpload /> */}
      </BNPLBody>

      <Footer
        disabled={!file}
        loading={loading}
        onBack={onBack}
        onNext={submit}
      />
    </>
  );
}

export { BNPLFileUpload };

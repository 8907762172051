import { useTranslation } from '@lendsqr/lingua-react';
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { pickBy, identity } from 'lodash';
import get from 'lodash.get';
import { Button, Spinner } from '@chakra-ui/react';
import {
  FieldError,
  Merge,
  FieldErrorsImpl,
  Control,
  DeepMap,
  UseFormRegisterReturn,
} from 'react-hook-form';
import moment from 'moment';
import { FormDataInterface, LoanData } from '../../../utils/interfaces';
import toast from 'react-hot-toast';
import useApplicationStore from '../../../state';
import { useField, useFormikContext } from 'formik';

const replaceDoubleBraces = (str: string, result: any) => {
  return str.replace(/{{(.+?)}}/g, (_, g1) => result || g1);
};

type FieldProps = {
  name: string;
  label: string;
  className?: string;
  info?: React.ReactNode;
  parameters: Record<string, any>;
  isInvalid: FieldError | Merge<FieldError, FieldErrorsImpl> | undefined;
  id: string;
  required?: boolean;
  register?: any;
  setValue?: any;
  getValue?: any;
  setError?: any;
  clearErrors?: any;
  control: Control<FormDataInterface>;
  errors: DeepMap<FormDataInterface, FieldError>;
  formHook?: UseFormRegisterReturn<string>;
};

const APITextField = ({
  label,
  name,
  parameters,
  id,
  setError,
  setValue,
  clearErrors,
}: FieldProps) => {
  const [loan_data] = useApplicationStore((state) => [
    state.loan_data as LoanData,
  ]);

  const [field, meta] = useField(id);
  const { values, setValues } = useFormikContext<Record<string, any>>();

  const { translate } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleTriggerResponseError = (error: any) => {
    parameters.assign.forEach((assign: { key: string; value: string }) => {
      setValue(assign.value, '');
    });
    toast.error(error?.response?.data?.message || error.message);

    setLoading(false);
  };

  const triggerResponse = useCallback(
    async (data: any) => {
      if (!data) {
        setError(id, {
          type: 'manual',
          message: `${name} is required`,
        });

        return;
      }

      const params: {
        url: string;
        method: string;
        headers: any;
        params?: string;
        data?: any;
      } = {
        url: replaceDoubleBraces(parameters.url, data),
        method: parameters.method,
        headers: parameters.headers,
      };

      if (parameters.query) {
        params.params = JSON.parse(
          replaceDoubleBraces(JSON.stringify(parameters.query), data)
        );
      }

      if (parameters.headers) {
        params.headers = JSON.parse(
          replaceDoubleBraces(JSON.stringify(parameters.headers), {
            ...data,
            org_id: loan_data.org_id,
          })
        );
      }

      if (parameters.body) {
        params.data = {};
        const keys = JSON.parse(
          replaceDoubleBraces(JSON.stringify(parameters.body), data)
        );
        params.data = keys;
      }

      const cleanedObject = pickBy(params, identity);

      try {
        clearErrors(id);
        setLoading(true);
        await axios(cleanedObject)
          .then((response) => {
            if (response.data.data.message !== null) {
              setError(id, {
                type: 'manual',
                message: `${response.data.data.message}`,
              });

              parameters.assign.forEach(
                (assign: { key: string; value: string }) => {
                  tempValues[assign.value] = get('', assign.key);
                }
              );
              return;
            }

            const tempValues: any = {};

            parameters.assign.forEach(
              (assign: { key: string; value: string }) => {
                if (
                  assign.value === 'loan_history' &&
                  response.data.data.loanHistoryDetails
                ) {
                  tempValues[assign.value] =
                    response.data.data.loanHistoryDetails.length.toString();
                } else if (
                  assign.value === 'outstanding_loan_amount' &&
                  response.data.data.loanHistoryDetails
                ) {
                  tempValues[assign.value] =
                    response.data.data.loanHistoryDetails.reduce(
                      (acc: any, history: any) =>
                        history.outstandingAmount + acc,
                      0
                    );
                } else if (assign.value.includes('date')) {
                  tempValues[assign.value] = moment(
                    '03-01-2024 08:18:00+0000',
                    'DD-MM-YYYY HH:mm:ssZ'
                  ).format('YYYY-MM-DD[T]HH:mm:ss');
                } else {
                  tempValues[assign.value] = get(response.data, assign.key);
                }
              }
            );

            setValues({ ...values, ...tempValues });
          })
          .then(() => {
            setLoading(false);
          });
      } catch (err) {
        handleTriggerResponseError(err);
      }
    },
    [values]
  );

  return (
    <div className="input-holder-holder">
      <div className="input-holder w-300px">
        <input
          type="text"
          className="form-control radius-4"
          placeholder={label}
          {...field}
          id={field.name}
        />

        <label htmlFor={field.name} className="input-label">
          {label}
        </label>
      </div>

      <Button
        h="1.75rem"
        size="sm"
        minW={'72px'}
        className="api-button"
        onClick={() => triggerResponse(field.value)}
      >
        {loading ? (
          <Spinner size="xs" />
        ) : (
          translate('invitation-web-app-submit')
        )}
      </Button>

      {meta.error && meta.touched && <p className="error">{meta.error}</p>}
    </div>
  );
};

export { APITextField };

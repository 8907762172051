import useApplicationStore from '../../state';
import { Organization } from '../../utils/interfaces';
import lendSqrLogo from '../../assets/logo.svg';
import { LanguageDropdown } from '../LanguageDropdown';
import { SideItem } from './SideItem';

function Sidebar({ sideSteps }: Readonly<{ sideSteps: Array<any> }>) {
  const [organization, screen, bnpl_conf] = useApplicationStore((state) => [
    state.organization as Organization,
    state.bnpl_screen,
    state.bnpl_conf,
  ]);

  return (
    <div className="helper-sidebar ">
      <div className="helper-side-lang-h helper-padding">
        <img
          src={
            organization.logo_url || organization.short_logo_url || lendSqrLogo
          }
          alt="Organization logo"
          className="helper-side-logo"
        />

        <LanguageDropdown />
      </div>

      <div className="helper-padding">
        <p className="heading-20 mb-12">{bnpl_conf?.title ?? 'Loan Product'}</p>

        {sideSteps?.map((item, index) => {
          return (
            <SideItem
              key={item.title}
              name={item.title}
              index={index + 1}
              selected={screen === item.screen}
              last={index === sideSteps.length - 1}
            />
          );
        })}
      </div>
    </div>
  );
}

export { Sidebar };

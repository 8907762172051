import { useEffect, useMemo, useState } from 'react';
import { PasswordField } from '../../base/PasswordField';
import { FormLayout } from '../layout/FormLayout';
import usePasswordValidation from '../../../utils/usePasswordValidation';
import { useTranslation } from '@lendsqr/lingua-react';
import { Spacer } from '../../base/Spacer';
import { PinInput, PinInputField } from '@chakra-ui/pin-input';
import { HStack } from '@chakra-ui/react';
import useApplicationStore from '../../../state';
import { ScreenState } from '../bnpl';
import { LoanData } from '../../../utils/interfaces';

const initialValues = {};

function Pin() {
  const { translate } = useTranslation();
  const [password, setPassword] = useState('');
  const { validate } = usePasswordValidation();
  const [pin, setPin] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtp, setIsOtp] = useState(false);

  const {
    createLoan,
    loading,
    code,
    setScreen,
    setValues,
    initPayment,
    completePayment,
    loanType,
    bnpl_conf,
    next,
    back,
  } = useApplicationStore((state) => ({
    createLoan: state.createLoan,
    loading: state.loading,
    code: state.code,
    loan_data: state.loan_data as LoanData,
    setScreen: state.setScreen,
    setValues: state.setValues,
    initPayment: state.initPayment,
    completePayment: state.completePayment,
    loanType: state.loanType,
    bnpl_conf: state.bnpl_conf,
    next: state.next,
    back: state.back,
  }));

  const onSubmit = async () => {
    try {
      if (loanType === 'pay_now' && !isOtp) {
        await initPayment({ password, pin });
        setIsOtp(true);
      } else if (loanType === 'pay_now' && isOtp) {
        await completePayment(otp);

        next(ScreenState.PIN, ScreenState.CONFIRMATION);
      } else if (!isOtp) {
        createLoan({ password, pin });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onBack = () => {
    back();
  };

  useEffect(() => {
    if (code === 'card-bank-mismatch') {
      setScreen(ScreenState.ADD_BANK);
      setValues({ code: '' });
    }
  }, [code]);

  const isDisabled = useMemo(() => {
    if (!isOtp) {
      return pin.length < 4;
    }

    return otp.length < 6;
  }, [pin, otp]);

  return (
    <FormLayout
      onBack={onBack}
      onSubmit={onSubmit}
      title={
        bnpl_conf?.authentication_setup?.description ??
        'Enter your pin for your account'
      }
      initialValues={initialValues}
      disabled={isDisabled}
      loading={loading}
    >
      {/* <PasswordField></PasswordField> */}
      <Spacer height={20} />

      {!isOtp && (
        <>
          <PasswordField
            password={password}
            setPassword={setPassword}
            validate={validate}
            name="password"
            label={translate('lender-web-app-password')}
          />

          <Spacer height={32} />

          <p className="body-regular">Pin</p>

          <Spacer height={8} />
          <HStack spacing="1rem" w="full">
            <PinInput onChange={(value) => setPin(value)} mask>
              {Array(4)
                .fill('')
                .map((_, i) => (
                  <PinInputField
                    key={`index_${i}`}
                    fontSize="2rem"
                    h="3rem"
                    w="full"
                  />
                ))}
            </PinInput>
          </HStack>
        </>
      )}

      {isOtp && (
        <>
          <p className="body-regular">Enter otp sent to you</p>

          <Spacer height={8} />
          <HStack spacing="1rem" w="full">
            <PinInput onChange={(value) => setOtp(value)} mask>
              {Array(6)
                .fill('')
                .map((_, i) => (
                  <PinInputField
                    key={`index_${i}`}
                    fontSize="2rem"
                    h="3rem"
                    w="full"
                  />
                ))}
            </PinInput>
          </HStack>
        </>
      )}
    </FormLayout>
  );
}

export { Pin };

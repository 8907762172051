import { useState } from 'react';
import { useTranslation } from '@lendsqr/lingua-react';
import { Spacer } from './Spacer';
import { useField } from 'formik';

type passwordType = {
  password: string;
  setPassword: (password: string) => void;
  validate: (value: string) => void;
  showHint?: boolean;
  name?: string;
  label: string;
  match?: string;
};

function PasswordField({
  password,
  setPassword,
  validate,
  showHint,
  label,
  match,
  name,
}: Readonly<passwordType>) {
  const [type, setType] = useState('password');
  const { translate } = useTranslation();
  const [passwordColor, setPasswordColor] = useState('bg-gray');
  const [passwordValid, setPasswordValid] = useState(0);

  const [, meta, helpers] = useField(name ?? '');

  const handleChange = async (e: any) => {
    let valid = 0;
    const { value } = e.target;
    setPassword(value);
    validate(value);
    if (value.length > 7) {
      valid += 25;
    }
    if (/[A-Z]/.test(value)) {
      valid += 25;
    }
    if (/[a-z]/.test(value)) {
      valid += 25;
    }
    if (/[=!@#&$%]/.test(value)) {
      valid += 25;
    }
    if (valid < 50) {
      setPasswordColor('bg-danger');
      helpers.setValue('');
    } else if (valid === 100) {
      setPasswordColor('bg-success');

      helpers.setValue(value);

      if (match && match !== value) {
        setTimeout(() => {
          helpers.setError('Passwords do not match');
        }, 10);

        helpers.setError('Passwords do not match');
        helpers.setTouched(true);
      }
    } else {
      helpers.setValue('');
      setPasswordColor('bg-warning');
    }

    setPasswordValid(valid);
  };

  return (
    <>
      <div className="input-holder-holder">
        <div className="input-holder">
          <input
            className="form-control radius-4"
            onChange={handleChange}
            value={password}
            type={type}
            placeholder="password"
            onBlur={() => helpers.setTouched(true)}
          />

          <label className={`input-label`}>{label}</label>

          <span
            onClick={() =>
              setType((t) => (t === 'password' ? 'text' : 'password'))
            }
            className="input-icon"
            id="toggle-icon"
          >
            {type === 'password' ? (
              <svg width="20" height="20" className="eye">
                <use xlinkHref="/icon-sprite.svg#eye" />
              </svg>
            ) : (
              <svg width="20" height="20" className="eye-off">
                <use xlinkHref="/icon-sprite.svg#eye-close" />
              </svg>
            )}
          </span>
        </div>

        {meta.touched && meta.error && !showHint && (
          <small className="error">
            {meta.touched && !!meta.error && meta.error}
          </small>
        )}
      </div>

      <Spacer height={8} />

      {showHint && (
        <div className="d-flex row-between">
          <div className="progress">
            <div
              className={`progress-bar bg-gray ${passwordColor}`}
              style={{ width: '100%' }}
              aria-valuenow={100}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
          <div className="progress">
            <div
              className={`progress-bar bg-gray ${
                passwordValid > 25 && passwordColor
              }`}
              role="progress"
              style={{ width: '100%' }}
              aria-valuenow={100}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
          <div className="progress">
            <div
              className={`progress-bar bg-gray ${
                passwordValid > 50 && passwordColor
              }`}
              role="progress"
              style={{ width: '100%' }}
              aria-valuenow={100}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
          <div className="progress">
            <div
              className={`progress-bar bg-gray ${
                passwordValid > 90 && passwordColor
              }`}
              role="progress"
              style={{ width: '100%' }}
              aria-valuenow={100}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
      )}

      {showHint && (
        <>
          <Spacer height={6} />

          <p className="sm-regular">
            {passwordValid === 100
              ? "That's a good password!"
              : translate('lender-web-app-password-requirment')}
          </p>
        </>
      )}
    </>
  );
}

export { PasswordField };

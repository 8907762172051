import { useCallback, useMemo, useState } from 'react';
import { FormLayout } from '../layout/FormLayout';
import * as Yup from 'yup';
import { Textfield } from '../../base/Textfield';
import { BVNField } from '../../base/BVNField';
import moment from 'moment';
import { DateField } from '../../base/DateField';
import { Spacer } from '../../base/Spacer';
import useApplicationStore from '../../../state';

function BVNValidation() {
  const [isBVNEntered, setIsBVNEntered] = useState(false);

  const [
    bnpl_values,
    verifyBVN,
    createBVN,
    loading,
    bnpl_conf,
    back,
    setBNPLValues,
  ] = useApplicationStore((state) => [
    state.bnpl_values,
    state.verifyBVN,
    state.createBVN,
    state.loading,
    state.bnpl_conf,
    state.back,
    state.setBNPLValues,
  ]);

  const pastDate = moment().subtract(18, 'years');

  const onBack = useCallback(() => {
    back();
  }, []);

  const onSubmit = useCallback(
    async (values: any) => {
      setBNPLValues(values);
      if (!isBVNEntered) {
        try {
          await verifyBVN(
            {
              bvn: values?.bvn.toString(),
              email: bnpl_values?.email_address,
              phone_number: bnpl_values?.phone_number.replace('+', ''),
            },
            bnpl_values?.phone_number
          );

          setIsBVNEntered(true);
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          await createBVN(
            {
              phone: values?.last_five,
              dob: values?.dob,
              password: bnpl_values?.password,
            },
            'verify'
          );
        } catch (error) {
          console.error(error);
        }
      }
    },
    [isBVNEntered]
  );

  const validation = useMemo(() => {
    const bvnSchema = Yup.object({
      bvn: Yup.string()
        .min(11, 'Please enter a valid bvn')
        .max(11, 'Please enter a valid bvn')
        .required('Please enter a bvn'),
    });

    const extraSchema = Yup.object({
      dob: Yup.date().required('Please enter your DOB'),
      last_five: Yup.string()
        .min(5, 'Enter the last 5 digits')
        .max(5, 'Enter the last 5 digits')
        .required('Enter the last 5 digits'),
    });

    if (!isBVNEntered) {
      return bvnSchema;
    } else {
      return bvnSchema.concat(extraSchema);
    }
  }, [isBVNEntered]);

  return (
    <FormLayout
      initialValues={{ ...bnpl_values }}
      validationSchema={validation}
      onSubmit={onSubmit}
      onBack={onBack}
      loading={loading}
      title={
        bnpl_conf?.identity_validation?.description ??
        'Your BVN does not give us access to your account, we can only access your full name, mobile number and date of birth.'
      }
    >
      <Spacer height={20} />

      <BVNField label="BVN" name="bvn" />

      {isBVNEntered && (
        <>
          <Spacer height={32} />

          <DateField maxDate={pastDate} label="Date of birth" name="dob" />

          <Spacer height={32} />

          <Textfield
            label="Last 5 digits of your mobile number"
            name="last_five"
            type="number"
            hint="This is the phone number linked to your BVN"
          />
        </>
      )}
    </FormLayout>
  );
}

export { BVNValidation };

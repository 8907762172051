import { WithChildrenProp } from './bnpl/bnpl';

function InfoLayout({ children }: Readonly<WithChildrenProp>) {
  return (
    <div className="background-disabled padding-20-16 radius-4 full-width">
      {children}
    </div>
  );
}

export { InfoLayout };

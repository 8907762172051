import { useEffect } from 'react';
import './integrations-component.css';

interface IntegrationsProps {
  visible?: boolean;
  access_token?: string;
  type?: string;
  onSuccess?: (data: unknown) => void;
  onFailed?: (err: unknown, data: unknown) => void;
}

export default function IntegrationsComponent({
  visible = false,
  access_token = '',
  type = '',
  onSuccess = function onSuccess(data: unknown) {
    console.error(data);
  },
  onFailed = function onFailed(err: unknown, data: unknown) {
    console.error(err, data);
  },
}: IntegrationsProps) {
  useEffect(() => {
    const listener = (event: {
      data: { command: string; message: any; data: any };
      origin: string;
    }) => {
      if (
        [
          'https://standard.paystack.co',
          'https://checkout.seerbitapi.com',
          'https://test.integrations.lendsqr.com',
          'https://integrations.lendsqr.com',
          'https://lendsqr.com',
          'https://checkout.stripe.com',
        ].includes(event.origin)
      ) {
        if (event?.data?.command === 'error') {
          onFailed(event?.data?.message, event?.data?.data || null);
        } else {
          onSuccess(event?.data?.data || null);
        }
      }
    };
    window.addEventListener('message', listener, false);

    return () => window.removeEventListener('message', listener, false);
  }, []);

  if (!visible) return null;
  return (
    <div className="integrations-container">
      <iframe
        id="integrations"
        title="Paystack"
        allow="camera;microphone"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: 0,
        }}
        src={`${import.meta.env.VITE_REACT_APP_INTEGRATIONS_URL}/${
          type ?? ''
        }?token=${access_token}`}
        width="100%"
        height="100%"
      />
    </div>
  );
}

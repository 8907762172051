import { useEffect, useState } from 'react';
import { BNPLBody } from '../layout/BNPLBody';
import { Footer } from '../Footer';
import { Information } from './Information';
import { RepaymentBreakdown } from './RepaymentBreakdown';
import { ScreenState } from '../bnpl';
import useApplicationStore from '../../../state';
import { LoanData } from '../../../utils/interfaces';
import { isOnlyCLF } from '../../../utils/formatter';

const Stage = {
  INFORMATION: 'INFORMATION',
  BREAKDOWN: 'BREAKDOWN',
};

function OrderInformation() {
  const [stage, setStage] = useState(Stage.INFORMATION);

  const [
    setScreen,
    loading,
    loan_data,
    bnpl_values,
    loanType,
    getLoanRepaymentBreakdown,
    next,
    back,
  ] = useApplicationStore((state) => [
    state.setBNPLScreen,
    state.loading,
    state.loan_data as LoanData,
    state.bnpl_values,
    state.loanType,
    state.getLoanRepaymentBreakdown,
    state.next,
    state.back,
  ]);

  const render = () => {
    switch (stage) {
      case Stage.INFORMATION:
        return <Information />;
      case Stage.BREAKDOWN:
        return <RepaymentBreakdown />;
    }
  };

  const handleNext = async () => {
    if (loanType === 'pay_now' && isOnlyCLF(loan_data)) {
      next(ScreenState.ORDER_INFORMATION, ScreenState.CONFIGURABLE_FORM);
      // setScreen(ScreenState.CONFIGURABLE_FORM);
      return;
    }

    if (loanType === 'pay_now') {
      next(ScreenState.ORDER_INFORMATION, ScreenState.PAYMENT);
      return;
    }

    if (
      (!bnpl_values.proposed_tenor || !bnpl_values.proposed_tenor_period) &&
      loanType === 'pay_later'
    ) {
      setScreen(ScreenState.OFFER_SELECTION);
      return;
    }

    if (stage === Stage.INFORMATION) {
      setStage(Stage.BREAKDOWN);
    } else if (stage === Stage.BREAKDOWN) {
      if (loan_data.product.attributes.show_only_clf) {
        next(ScreenState.ORDER_INFORMATION, ScreenState.CONFIGURABLE_FORM);
      } else {
        next(ScreenState.ORDER_INFORMATION, ScreenState.PERSONAL_INFORMATION);
      }
    }
  };

  const handleBack = () => {
    if (stage === Stage.BREAKDOWN) {
      setStage(Stage.INFORMATION);
    } else if (stage === Stage.INFORMATION) {
      back();
    }
  };

  useEffect(() => {
    getLoanRepaymentBreakdown({
      principal: bnpl_values.requested_amount,
      product_id: loan_data?.product?.id,
      start_date: bnpl_values?.proposed_payday,
      tenor_period: bnpl_values?.proposed_tenor_period,
      tenor: bnpl_values.proposed_tenor,
    });
  }, []);

  return (
    <>
      <BNPLBody>{render()}</BNPLBody>

      <Footer loading={loading} onBack={handleBack} onNext={handleNext} />
    </>
  );
}

export { OrderInformation };

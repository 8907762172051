import { money, repaymentDates } from '../../utils/formatter';
import { TableHeader } from '../bnpl/bnpl';
import { InfoLayout } from '../InfoLayout';
import { Spacer } from './Spacer';

type RowInfoTableProp = {
  title: string;
  table_header: Array<TableHeader>;
  content: Array<any>;
  currency?: string;
};

function RowInfoTable({
  title,
  table_header,
  content,
  currency,
}: Readonly<RowInfoTableProp>) {
  return (
    <InfoLayout>
      <p className="md-bold">{title}</p>

      <Spacer height={20} />

      <div className="table-parent">
        <div className="table-holder">
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                {table_header.map((header) => {
                  return (
                    <th
                      style={{ textAlign: 'right' }}
                      className="sm-regular"
                      key={header.title}
                    >
                      {header.title}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {content.map((row, index) => {
                return (
                  <tr
                    key={`row${index}`}
                    style={{
                      padding: '12px 0px',
                      height: '44px',
                      borderBottom:
                        index !== content.length - 1
                          ? '1px solid rgba(9, 30, 66, 0.06)'
                          : '',
                    }}
                  >
                    {table_header.map((header) => {
                      return (
                        <td
                          className="sm-bold"
                          key={`${header.title}${index}`}
                          style={{ textAlign: 'right' }}
                        >
                          {!header.accessor && index + 1}

                          {header.accessor &&
                            header.type === 'date' &&
                            repaymentDates(row[header.accessor])}

                          {header.accessor &&
                            header.type === 'money' &&
                            money(row[header.accessor], currency ?? 'NGN')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </InfoLayout>
  );
}

export { RowInfoTable };

import { useCallback, useMemo, useState } from 'react';
import useApplicationStore from '../../../state';
import { ScreenState } from '../bnpl';
import * as Yup from 'yup';
import { useTranslation } from '@lendsqr/lingua-react';
import { isOnlyCLF } from '../../../utils/formatter';
import { LoanData } from '../../../utils/interfaces';
import { startOfToday } from 'date-fns';
import { SelectField } from '../../base/SelectField';
import { FormLayout } from '../layout/FormLayout';
import { Spacer } from '../../base/Spacer';
import { Textfield } from '../../base/Textfield';
import { DateField } from '../../base/DateField';

function EducationalInformation() {
  const { translate } = useTranslation();

  const [isEmployed, setIsEmployed] = useState(true);

  const onBack = useCallback(() => {
    back();
  }, []);

  const minDate = startOfToday();

  const [
    loan_data,
    getOptionsFor,
    setBNPLValues,
    bnpl_conf,
    next,
    back,
    preSubmitForm,
    loading,
    bnpl_values,
  ] = useApplicationStore((state) => [
    state.loan_data as LoanData,
    state.getOptionsByKey,
    state.setBNPLValues,
    state.bnpl_conf,
    state.next,
    state.back,
    state.preSubmitForm,
    state.loading,
    state.bnpl_values,
  ]);

  const onSubmit = useCallback(async (values: any) => {
    setBNPLValues(values);

    if (isOnlyCLF(loan_data)) {
      next(ScreenState.EDUCATIONAL_INFORMATION, ScreenState.CONFIGURABLE_FORM);
    } else {
      await preSubmitForm(ScreenState.EDUCATIONAL_INFORMATION);
    }
  }, []);

  const handleChangeEmployment = (status: string) => {
    if (status === 'Unemployed') {
      setIsEmployed(false);
    } else {
      setIsEmployed(true);
    }
  };

  const validation = useMemo(() => {
    return Yup.object().shape({
      educational_attainment: Yup.string().required(
        translate('lender-web-app-select-education-attainment')
      ),
      employment_status: Yup.string().required(
        translate('lender-web-app-select-employment-status')
      ),
      employment_category: Yup.string().when('employment_status', {
        is: 'Unemployed',
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required(),
      }),
      monthly_net_income: Yup.string().when('employment_status', {
        is: 'Unemployed',
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required(),
      }),
      current_employer: Yup.string().when('employment_status', {
        is: 'Unemployed',
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required(),
      }),
      work_start_date: Yup.date()
        .max(
          startOfToday(),
          translate(translate('lender-web-app-select-valid-date'))
        )
        .when('employment_status', {
          is: 'Unemployed',
          then: (schema) => schema.nullable(),
          otherwise: (schema) => schema.required(),
        }),
      work_email: Yup.string()
        .email(translate('lender-web-app-provide-valid-email'))
        .when('employment_status', {
          is: 'Unemployed',
          then: (schema) => schema.nullable(),
          otherwise: (schema) => schema.required(),
        }),
    });
  }, [translate]);

  return (
    <FormLayout
      initialValues={{ ...bnpl_values }}
      validationSchema={validation}
      onSubmit={onSubmit}
      onBack={onBack}
      title={
        bnpl_conf?.educational_information?.description ??
        "We'll use this information to verify your identity."
      }
      loading={loading}
    >
      {validation && (
        <>
          <Spacer height={20} />
          <SelectField
            name="educational_attainment"
            label={translate('lender-web-app-highest-education')}
            options={getOptionsFor('education_attainment')}
            groupClassName=""
            keyField="value"
          />

          <Spacer height={32} />

          <SelectField
            name="employment_status"
            label={translate('lender-web-app-employment-status')}
            options={getOptionsFor('employment_status')}
            keyField="value"
            onChange={handleChangeEmployment}
          />

          {isEmployed && (
            <>
              <Spacer height={32} />

              <SelectField
                name="employment_category"
                label={translate('lender-web-app-employment-category')}
                options={getOptionsFor('employment_category')}
                keyField="value"
              />

              <Spacer height={32} />

              <SelectField
                name="monthly_net_income"
                label={`${translate(
                  'lender-web-app-range-monthly-salary'
                )} (${loan_data?.currency})`}
                options={getOptionsFor('monthly_net_income')}
                keyField="value"
              />

              <Spacer height={32} />

              <Textfield
                name="current_employer"
                label={translate('lender-web-app-current-employer')}
                type="text"
              />

              <Spacer height={32} />

              <DateField
                maxDate={new Date()}
                initialDate={minDate}
                label="Work start date"
                name="work_start_date"
              />

              <Spacer height={32} />

              <Textfield
                name="work_email"
                label={translate('lender-web-app-work-email')}
                type="text"
              />
            </>
          )}
        </>
      )}
    </FormLayout>
  );
}

export { EducationalInformation };

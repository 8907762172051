import { useEffect, useState } from 'react';
import useApplicationStore from '../../../../state';
import { BNPLBody } from '../../layout/BNPLBody';
import { Footer } from '../../Footer';
import { EmptyCards } from './EmptyCards';
import { Spacer } from '../../../base/Spacer';
import CardModal from '../../../base/CardModal';
import IntegrationsComponent from '../../../../utils/IntegrationsComponent';
import { SelectCards } from './SelectCards';
import { ScreenState } from '../../bnpl';

interface BnplCardsType {
  onBack: () => void;
  onNext: () => void;
}

function BNPLCards({ onBack, onNext }: BnplCardsType) {
  const [showModal, setShowModal] = useState(false);
  const [showPaystackModal, setShowPaystackModal] = useState(false);

  const [
    fetchCards,
    cards,
    cardsStatus,
    token,
    selectedCard,
    setScreen,
    code,
    setValues,
    loading,
    bnpl_conf,
  ] = useApplicationStore((state) => [
    state.fetchCards,
    state.cards,
    state.cardsStatus,
    state.loginToken,
    state.selectedCard,
    state.setBNPLScreen,
    state.code,
    state.setValues,
    state.loading,
    state.bnpl_conf,
  ]);

  useEffect(() => {
    fetchCards();
  }, []);

  const nextClick = () => {
    onNext();
  };

  const backClick = () => {
    onBack();
  };

  const addClick = () => {
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const closePaystackModal = async () => {
    await fetchCards('refetching');
    setShowPaystackModal(false);
    setShowModal(false);
  };

  useEffect(() => {
    if (code === 'card-bank-mismatch') {
      setScreen(ScreenState.ADD_BANK);
      setValues({ code: '' });
    }
  }, [code]);

  return (
    <>
      <BNPLBody title="Select a card for payment">
        <Spacer height={20} />
        {cardsStatus === 'empty' && (
          <EmptyCards
            title="No cards"
            description={
              bnpl_conf?.add_card?.description ??
              'You have not added any cards yet. Click ‘Add a new card’ to get started.'
            }
            addClick={addClick}
          />
        )}

        {cardsStatus === 'success' && cards && cards?.length > 0 && (
          <>
            <SelectCards />
            <Spacer height={16} />
            <p className="body-regular text-center cursor" onClick={addClick}>
              Add new card
            </p>
          </>
        )}
      </BNPLBody>

      <Footer
        loading={loading}
        disabled={!selectedCard?.id}
        onBack={backClick}
        onNext={nextClick}
      />

      <CardModal
        isOpen={showModal}
        onClose={onClose}
        setShowPaystackModal={setShowPaystackModal}
        setShowModal={setShowModal}
      >
        <IntegrationsComponent
          visible={showPaystackModal}
          access_token={token}
          type="card"
          onSuccess={() => {
            closePaystackModal();
            fetchCards();
          }}
          onFailed={() => {
            closePaystackModal();
          }}
        />
      </CardModal>
    </>
  );
}

export { BNPLCards };

import bnpl_x from '../../../public/bnpl-x.svg';
import { Spacer } from '../base/Spacer';
import { LanguageDropdown } from '../LanguageDropdown';

type HeaderType = {
  title: string;
  onClose: () => void;
};

function Header({ title, onClose }: Readonly<HeaderType>) {
  return (
    <div className="bnlp-header helper-padding">
      <p className="heading-20">{title}</p>

      <div className="flex">
        <div className="mob-show">
          <LanguageDropdown />
        </div>

        <Spacer width={8} />
        <img src={bnpl_x} alt="close icon" onClick={onClose} />
      </div>
    </div>
  );
}

export { Header };

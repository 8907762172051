import { useMemo } from 'react';
import { RowInfoDetails } from '../../base/RowInfoDetails';
import { RowInfoTable } from '../../base/RowInfoTable';
import { Spacer } from '../../base/Spacer';
import useApplicationStore from '../../../state';
import { LoanData } from '../../../utils/interfaces';
import { money, repaymentDates } from '../../../utils/formatter';
import { capitalize } from 'lodash';

const table_header = [
  {
    title: '#',
    type: 'index',
  },
  {
    title: 'DUE DATE',
    type: 'date',
    accessor: 'date',
  },
  {
    title: 'AMOUNT (NGN)',
    type: 'money',
    accessor: 'amount',
  },
  {
    title: 'OUTSTANDING (NGN)',
    type: 'money',
    accessor: 'cumulative',
  },
];

function RepaymentBreakdown() {
  const [bnpl_values, loan_data, schedules, bnpl_conf, equityContribution] =
    useApplicationStore((state) => [
      state.bnpl_values,
      state.loan_data as LoanData,
      state.schedules,
      state.bnpl_conf,
      state.equityContribution,
    ]);

  const headerInfo = useMemo(() => {
    return [
      {
        title: 'Repayment frequency',
        value: capitalize(loan_data?.frequency),
      },
      {
        title: 'Repayment date',
        value: repaymentDates(schedules[0]?.due_date),
      },
      {
        title: 'Repayment amount',
        value: money(bnpl_values?.requested_amount, loan_data?.currency),
      },
      {
        title: bnpl_conf?.equity_contribution?.label ?? 'Equity contribution',
        value: money(equityContribution, loan_data?.currency),
      },
    ];
  }, [bnpl_values, schedules, equityContribution]);

  // useEffect(() => {
  //   getLoanRepaymentBreakdown({
  //     principal: bnpl_values.requested_amount,
  //     product_id: loan_data?.product?.id,
  //     start_date: bnpl_values?.proposed_payday,
  //     tenor_period: bnpl_values?.proposed_tenor_period,
  //     tenor: bnpl_values.proposed_tenor,
  //   });
  // }, []);

  const content = useMemo(() => {
    return schedules.map((schedule) => {
      return {
        date: schedule.due_date,
        amount: schedule.amount_due,
        cumulative: schedule.balance,
      };
    });
  }, [schedules]);

  return (
    <div>
      <RowInfoDetails content={headerInfo} />

      <Spacer height={20} />

      <RowInfoTable
        title="Repayment schedule"
        table_header={table_header}
        content={content}
        currency="NGN"
      />
    </div>
  );
}

export { RepaymentBreakdown };

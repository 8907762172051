import { useMemo } from 'react';

import useApplicationStore from '../state';
import { LoanData } from '../utils/interfaces';
import '../assets/styles/nav.scss';

import { Helmet } from 'react-helmet-async';
import LendsqrLogo from '../assets/lendsqr-fav.png';
import { Sidebar } from './bnpl/Sidebar';
import { Content } from './bnpl/Content';
import { ScreenState } from './bnpl/bnpl';
import { isOnlyCLF } from '../utils/formatter';

const BNPLLayout = () => {
  const [loan_data, logged_in, user_activated, loanType, bnpl_conf] =
    useApplicationStore((state) => [
      state.loan_data as LoanData,
      state.logged_in,
      state.user_activated,
      state.loanType,
      state.bnpl_conf,
    ]);

  const sideSteps = useMemo(() => {
    const steps = [
      {
        screen: ScreenState.OFFER_SELECTION,
        title: bnpl_conf?.product_selection?.label ?? 'Select Product',
      },
    ];

    if (!logged_in) {
      steps.push({
        screen: ScreenState.ACCOUNT_INFORMATION,
        title: bnpl_conf?.account_information?.label ?? 'Account Information',
      });
    }

    if (!user_activated) {
      steps.push({
        screen: ScreenState.BVN_VALIDATION,
        title: bnpl_conf?.identity_validation?.label ?? 'BVN Validation',
      });

      if (bnpl_conf?.selfie_validation?.enabled) {
        steps.push({
          screen: ScreenState.SELFIE_CHECK,
          title: bnpl_conf?.selfie_validation?.label ?? 'Selfie Check',
        });
      }
    }

    if (bnpl_conf?.enabled) {
      steps.push({
        screen: ScreenState.ORDER_INFORMATION,
        title: bnpl_conf?.order_information?.label ?? 'Order Information',
      });
    }

    if (!loan_data.product.attributes.show_only_clf && loanType !== 'pay_now') {
      steps.push({
        screen: ScreenState.PERSONAL_INFORMATION,
        title: bnpl_conf?.personal_information?.label ?? 'Personal Information',
      });

      steps.push({
        screen: ScreenState.EDUCATIONAL_INFORMATION,
        title:
          bnpl_conf?.educational_information?.label ??
          'Educational Information',
      });
    }

    if (isOnlyCLF(loan_data)) {
      steps.push({
        screen: ScreenState.CONFIGURABLE_FORM,
        title:
          bnpl_conf?.loan_request_information?.label ??
          loan_data?.product?.attributes?.additional_loan_data?.meta?.name ??
          bnpl_conf?.loan_request_information?.label ??
          'Dynamic form',
      });
    }

    if (loanType !== 'pay_now') {
      steps.push({
        screen: ScreenState.REVIEW_TERMS,
        title: bnpl_conf?.terms_review?.label ?? 'Review Terms',
      });
    }

    steps.push({
      screen: ScreenState.PAYMENT,
      title: bnpl_conf?.payments_processing?.label ?? 'Payment',
    });

    steps.push({
      screen: ScreenState.PAYMENT_METHODS,
      title: bnpl_conf?.payment_methods?.label ?? 'Payment Methods',
    });

    steps.push({
      screen: ScreenState.PIN,
      title: bnpl_conf?.authentication_setup?.label ?? 'Pin',
    });

    if (loan_data.product.attributes.require_guarantor) {
      steps.push({
        screen: ScreenState.GUARANTOR,
        title: 'Add Guarantor',
      });
    }

    steps.push({
      screen: ScreenState.CONFIRMATION,
      title: bnpl_conf?.transaction_confirmation?.label ?? 'Confirmation',
    });

    return steps;
  }, [logged_in, loan_data, user_activated, loanType]);

  return (
    <>
      <Helmet>
        <title> {`${loan_data.organization.name} | Loan Invitation`} </title>
        <link
          rel="icon"
          href={loan_data.organization.short_logo_url ?? LendsqrLogo}
        />
      </Helmet>

      <div className="helper-background">
        <div className="helper-modal">
          <Sidebar sideSteps={sideSteps} />
          <Content sideSteps={sideSteps} />
        </div>
      </div>
    </>
  );
};

export { BNPLLayout };

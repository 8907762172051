import { useEffect } from 'react';
import useApplicationStore from '../../../state';
import { LoanData } from '../../../utils/interfaces';
import { Spacer } from '../../base/Spacer';
import { IoCopyOutline } from 'react-icons/io5';
import { BNPLBody } from '../layout/BNPLBody';
import guarantorStyles from './_guarantor.module.scss';
import { copyToClipBoard } from '../../../utils';
import { Footer } from '../Footer';
import { ScreenState } from '../bnpl';

const BNPLGuarantor = () => {
  const {
    loan_data,
    generateGuarantorLink,
    status,
    loading,
    back,
    next,
    guarantorLink,
  } = useApplicationStore((state) => ({
    status: state.status,
    loading: state.loading,
    back: state.back,
    loan_data: state.loan_data as LoanData,
    generateGuarantorLink: state.generateGuarantorLink,
    next: state.next,
    guarantorLink: state.guarantorLink,
  }));

  useEffect(() => {
    !guarantorLink && generateGuarantorLink();
  }, []);

  const loanAttributes = loan_data?.product?.attributes;

  const guarantorData = [
    'Copy the unique link provided below.',
    `Share it with your guarantors (maximum of ${loanAttributes?.no_of_guarantors}).`,
    'Guarantors must use the link to accept the invitation and verify themselves',
  ];

  const handleNext = () => {
    next(ScreenState.GUARANTOR, ScreenState.CONFIRMATION);
  };

  return (
    <>
      <BNPLBody>
        <div className={guarantorStyles.guarantor}>
          <hgroup>
            <h3 className="md-bold">Why do I need guarantors?</h3>
            <Spacer height={8} />
            <p className="body-regular">
              {loanAttributes?.borrower_message.replace(
                '{no_of_guarantors}',
                String(loanAttributes?.no_of_guarantors)
              )}
            </p>
          </hgroup>
          <Spacer height={32} />
          <section>
            <h3 className="md-bold">How it works?</h3>
            <Spacer height={8} />

            <ul
              style={{
                marginInline: '20px',
              }}
            >
              {guarantorData.map((data, index) => (
                <li
                  className="body-regular"
                  style={{
                    listStyleType: 'number',
                  }}
                  key={index}
                >
                  {data}
                </li>
              ))}
            </ul>
          </section>
          <Spacer height={32} />

          <section
            style={{
              position: 'relative',
            }}
          >
            <div
              className="item-background"
              style={{
                borderRadius: '4px',
              }}
            ></div>
            <div className="flex-center-column padding-block-20">
              <h3 className="md-bold text-center">Your guarantor link</h3>
              <Spacer height={6} />
              {status === 'error' && (
                <p className="text-center md-regular">
                  An error occurred while generating guarantor link
                </p>
              )}
              {status === 'success' && guarantorLink ? (
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '.5rem',
                  }}
                >
                  <a
                    href={guarantorLink}
                    className="text-center"
                    target="_blank" rel="noreferrer"
                  >
                    {guarantorLink}
                  </a>
                  <IoCopyOutline
                    cursor="pointer"
                    onClick={() => copyToClipBoard(guarantorLink)}
                  />
                </span>
              ) : (
                <p className="text-center md-regular">
                  Generating guarantor Link...
                </p>
              )}
            </div>
          </section>
          <Spacer height={32} />

          <hgroup>
            <h3 className="md-bold">Note</h3>
            <Spacer height={8} />

            <p className="body-regular">
              Your loan application will not proceed until the guarantor accepts
              the invitation.
            </p>
          </hgroup>
        </div>
      </BNPLBody>
      <Footer loading={loading} onNext={handleNext} onBack={back} />
    </>
  );
};

export default BNPLGuarantor;
